/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("bootstrap")
global.$ = jQuery;
require("@nathanvda/cocoon")
require("jquery-ui")
// require("easy-autocomplete")
// require("./participate_user")

// require.context('../images', true)
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// stylesheets
require("../src/stylesheets/application.css.scss")

// document.addEventListener("turbolinks:load", () => {
$(document).ready(function () {
    $('input.not-finish-btn[type="submit"]').on('click', function (event) {
        $('input.not-finish-input').val('true');
    });

    $('input.finish-btn[type="submit"]').on('click', function (event) {
        $('input.not-finish-input').val('false');
    });

    $('input.finalized-btn[type="submit"]').on('click', function (event) {
        event.preventDefault()

    });

    $('#confirmFinalisedButton').on('click', function (event) {
        $('input.finalized-input').val('true');
        $('form.simple_form').submit();
    });


});

// });

